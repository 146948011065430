
.btn-submit {
    min-width: 120px;
    background: $__primarycolor;
    color: $__white;
    font-size: $__font__size__14;
    font-weight: $__bold;
    @include margin-padding(null, 14px 10px);
    @include transition(all 0.2s ease);
    &:hover{
        background: $__primarycolorhover;
        color: $__white;
    }
    @include respond-below(custom991) {
        min-width: $__auto;
        padding: 5px 7px;
    }
}
.btn-cancel {
    min-width: 120px;
    background: $__sandstone;
    color: $__white;
    font-size: $__font__size__14;
    font-weight: $__bold;
    @include margin-padding(null, 14px 10px);
    @include transition(all 0.5s ease);
    &:hover{
        background: #424b52;
        color: $__white;
     }
    @include respond-below(custom991) {
        min-width: $__auto;
        @include margin-padding(null, 5px 7px);
    }
}
.btn-primary {
    color: $__white;
    background-color: $__primarycolor !important;
    border-color: $__primarycolor !important;
}